"use client"

import { useState, useMemo, type CSSProperties } from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { NEXT_PUBLIC_GOOGLE_API_KEY } from "shared-config/clientConfig"

import { formatAddressGoogleMaps } from "../../../../utils/formatAddressGoogleMaps"
import { optionsGoogleSearch } from "../constants"
import InputSelect from "../input-select"
import { type TOption, type TInputSelectProps } from "../input-select/type"

export type TResultGoogleSearchAutoComplete = {
  addressName: string
  addressSecondary: string
  placeId: string
  lat: number
  lng: number
}

type TGoogleSearchAutoCompleteProps = Omit<TInputSelectProps, "onSelect"> & {
  onSelect: (data: TResultGoogleSearchAutoComplete) => void
  iconStyle?: {
    iconLeft?: CSSProperties
    iconRight?: CSSProperties
  }
  withChevron?: boolean
}

const GoogleSearchAutoComplete = ({
  onSelect,
  onClickCurrentLocation,
  onClickManualLocation,
  placeholder,
  iconLeft,
  iconRight,
  isClearFieldOnBlur,
  id,
  iconStyle,
  withChevron,
}: TGoogleSearchAutoCompleteProps) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: NEXT_PUBLIC_GOOGLE_API_KEY,
    language: "id",
    options: optionsGoogleSearch,
  })
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const options = useMemo(() => {
    return placePredictions.map(
      (item: google.maps.places.AutocompletePrediction) => ({
        label: item.structured_formatting.main_text,
        desc: item.structured_formatting.secondary_text,
        value: item.place_id,
        areaCode: "",
      })
    )
  }, [placePredictions])

  const handleSelectLocation = (newValue: unknown) => {
    placesService?.getDetails(
      {
        placeId: (newValue as TOption).value,
      },
      (placeDetails: google.maps.places.PlaceResult | null) => {
        if (
          placeDetails &&
          placeDetails.geometry &&
          placeDetails.geometry.location
        ) {
          const { placeId, addressName, addressSecondary } =
            formatAddressGoogleMaps({
              addressComponent: placeDetails.address_components,
              placeId: placeDetails.place_id || "",
            })
          const tempResult = {
            addressName,
            addressSecondary,
            placeId,
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          }
          onSelect(tempResult)
        } else {
          //HANDLE ERROR
          console.error("Place service detail error: result search not found")
        }
      }
    )
  }

  return (
    <div
      className={
        !!options.length && isMenuOpen ? "h-28" : isMenuOpen ? "h-21" : "h-auto"
      }
    >
      <InputSelect
        id={id}
        onChange={(input) => getPlacePredictions({ input })}
        options={options}
        onFocus={() => setIsMenuOpen(true)}
        onSelect={handleSelectLocation}
        onBlur={() => setIsMenuOpen(false)}
        isLoading={isPlacePredictionsLoading}
        onClickCurrentLocation={onClickCurrentLocation}
        onClickManualLocation={onClickManualLocation}
        placeholder={placeholder}
        iconLeft={iconLeft}
        iconRight={iconRight}
        isClearFieldOnBlur={isClearFieldOnBlur}
        iconStyle={iconStyle}
        withChevron={withChevron}
      />
    </div>
  )
}

export default GoogleSearchAutoComplete
