"use client"

import { useState } from "react"
import {
  type Control,
  type FieldPath,
  type UseFormClearErrors,
  useWatch,
} from "react-hook-form"
import { useDebounce } from "shared-utils"

import {
  type Region,
  type RegionLevel,
  type RegionPagination,
} from "../../../../federatedGql/graphql"
import useGetRegionSearch from "../../../hooks/useGetRegionSearch"
import { type TFormModalAdd, type TRegion } from "../../type"
import InputSelect from "../google/input-select"
import {
  type TInputSelectProps,
  type TOption,
} from "../google/input-select/type"

type TInputFieldSelectProps = Omit<TInputSelectProps, "onSelect" | "name"> & {
  control: Control<TFormModalAdd>
  name: FieldPath<TFormModalAdd>
  label?: string
  watchValues?: Array<FieldPath<TFormModalAdd>>
  onSelect: (
    val: TOption,
    data?: Pick<Region, "parentId" | "postalCode">
  ) => void
  level?: RegionLevel
  errorMessage?: string
  clearErrors: UseFormClearErrors<TFormModalAdd>
}

export const PER_PAGE = 10

const defaultPage = {
  page: 1,
  perPage: PER_PAGE,
}

const InputFieldSelect = ({
  control,
  name,
  label,
  watchValues = [],
  onChange,
  onSelect,
  level,
  errorMessage,
  clearErrors,
  disabled,
  id,
  ...restProps
}: TInputFieldSelectProps) => {
  const [pagination, setPagination] = useState<RegionPagination>(defaultPage)
  const [isEnableFetch, setIsEnableFetch] = useState<boolean>(false)
  const values = useWatch({
    control,
    name: [name, ...watchValues],
  })
  const inputValue = values?.[0] ?? ""
  const watchValue = values?.[1] as TRegion
  const debounceValue = useDebounce(inputValue, 500)

  const { data, isFetching } = useGetRegionSearch({
    variables: {
      level,
      search: debounceValue as string,
      parentIds: watchValue?.id ?? null,
      pagination,
    },
    enabled:
      (!!debounceValue || Boolean(watchValue?.id)) && !!level && isEnableFetch,
    select(tempData) {
      let options: TOption[] = []
      const specOptions =
        tempData?.items?.map((region, index) => ({
          label: region.name || "",
          value: region.id || "",
          areaCode: region.areaCode || "",
          key: index,
        })) || []

      const regionPagination = {
        currentPage: tempData?.currentPage,
        lastPage: tempData?.lastPage,
        perPage: tempData?.perPage,
        total: tempData?.total,
      }

      if ((pagination?.page || 1) > 1) {
        options = [...options, ...specOptions]
      } else {
        options = [...specOptions]
      }
      return {
        items: {
          ...tempData?.items,
        },
        regionPagination,
        options,
      }
    },
  })

  const handleChange = (val: string) => {
    setPagination(defaultPage)
    if (onChange) onChange(val)
    if (errorMessage && (val !== "" || val !== undefined)) clearErrors(name)
  }

  const handleLoadMore = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.currentTarget
    const scrolling = Math.floor(target.scrollHeight - target.scrollTop)
    if (
      scrolling <= target.clientHeight &&
      data?.regionPagination.currentPage !== data?.regionPagination.lastPage
    ) {
      setPagination((e) => ({
        page: Number(e.page || 1) + 1,
        perPage: PER_PAGE,
      }))
    }
  }

  return (
    <div className="w-full">
      <InputSelect
        label={label}
        value={inputValue as string}
        onChange={handleChange}
        onSelect={(opt: TOption) => {
          onSelect(opt, data?.items?.[opt.key || 0])
          if (errorMessage) clearErrors(name)
        }}
        disabled={disabled || (!!watchValues.length && !watchValue?.name)}
        options={data?.options}
        isLoading={isFetching}
        isFirstPage={pagination.page === 1}
        onFocus={() => setIsEnableFetch(true)}
        errorMessage={errorMessage}
        id={id}
        onScrollItems={handleLoadMore}
        {...restProps}
      />
    </div>
  )
}

export default InputFieldSelect
