import { MapPin } from "react-feather"

import { type TOption } from "../type"

const Options = ({
  option,
  onClick,
  index,
  id,
}: {
  option: TOption
  onClick: () => void
  index: number
  id?: string
}) => (
  <li
    tabIndex={0}
    id={`${id}-option-${index}`}
    className="list-menu hover:bg-tertiary25 py-2 align-top"
    onMouseDown={(e) => e.preventDefault()}
    onClick={onClick}
  >
    <div className="absolute left-4 top-3">
      <MapPin size={14} />
    </div>
    <div className="col-span-2">
      <p className="text-tertiary500 pb-1 text-sm">{option.label}</p>
      <p className="overflow-hidden text-ellipsis text-xs">
        {option?.desc || ""}
      </p>
    </div>
  </li>
)

export default Options
