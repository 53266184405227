type BadgeLabelProps = {
  text: string
}

const BadgeLabel = ({ text }: BadgeLabelProps) => (
  <div className="rounded-2 bg-tertiary25 px-2 py-1 text-xs font-bold text-tertiary300">
    {text}
  </div>
)

export default BadgeLabel
