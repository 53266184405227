import Image from "next/image"
import { Plus } from "react-feather"
import EmptyAddressImage from "shared-assets/images/empty-address.webp"
import { Button } from "shared-ui"

type TEmptyAddressProps = {
  onClick: () => void
  isSearch?: boolean
  id?: string
  hasPersona?: boolean
}

const EmptyAddress = ({
  onClick,
  isSearch,
  id = "address",
  hasPersona,
}: TEmptyAddressProps) => {
  return (
    <div
      className="rounded-4 border-tertiary50 flex w-full flex-col items-center self-center border border-solid px-4 py-6"
      style={{
        boxShadow:
          "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Image
        src={EmptyAddressImage}
        alt="empty-address-image"
        width={400}
        height={200}
      />
      <div className="mt-6 flex flex-col items-center">
        <span className="text-tertiary500 text-lg font-bold">
          {!hasPersona ? "Akses Belum Tersedia" : "Daftar Alamat Kosong"}
        </span>
        <p className="text-tertiary300 mt-4 text-base">
          {isSearch && hasPersona && "Alamat yang kamu cari tidak ditemukan."}
          {!isSearch &&
            hasPersona &&
            "Alamat yang telah dimasukkan akan tersimpan di halaman ini."}
          {!isSearch &&
            !hasPersona &&
            "Anda tidak dapat menambah alamat karena belum memiliki akses yang terverifikasi."}
        </p>
      </div>
      {!isSearch && hasPersona && (
        <div className="mt-6">
          <Button
            id={`${id}-btn-add`}
            className="text-sm font-semibold"
            onClick={onClick}
            IconLeft={Plus}
          >
            Tambah Alamat Baru
          </Button>
        </div>
      )}
    </div>
  )
}

export default EmptyAddress
