import { Button, Spinner, Modal } from "shared-ui"

type TDeleteAddressConfirmationModalProps = {
  isActive: boolean
  onClose: () => void
  onDelete: () => void
  loading?: boolean
  id?: string
}

const DeleteAddressConfirmationModal = ({
  isActive,
  onClose,
  onDelete,
  loading,
  id,
}: TDeleteAddressConfirmationModalProps) => {
  return (
    <div>
      <Modal
        open={isActive}
        modalId="modal-delete-address"
        title="Hapus Alamat Ini?"
        closable
        classNames={{
          content: "w-[528px]",
        }}
        onOpenChange={onClose}
      >
        <div className="text-tertiary300 mb-6 mt-4 text-sm font-normal">
          Setelah dihapus, Anda harus memasukkan ulang alamat jika ingin
          mengirim ke lokasi ini lagi.
        </div>
        <div className="flex justify-between gap-3 pt-6">
          <Button
            className="w-full"
            variant="outline2"
            color="tertiary"
            onClick={onClose}
            id={`${id}-cancel`}
          >
            Batal
          </Button>
          <Button
            className="w-full"
            onClick={onDelete}
            disabled={loading}
            IconRight={() =>
              loading ? <Spinner color="primary" size="small" /> : <></>
            }
            id={`${id}-confirm`}
          >
            Hapus
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteAddressConfirmationModal
