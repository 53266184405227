import { Skeleton } from "shared-ui"

const SkeletonLoading = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, i) => (
        <div key={i} className="list-menu mb-4 h-6 overflow-hidden first:mt-4">
          <Skeleton bgColor="bg-tertiary50" />
        </div>
      ))}
    </>
  )
}
export default SkeletonLoading
