import { create } from "zustand"

import { type TFormType } from "../modal-add-address/type"

type TFormLevelAddAddressState = {
  level: TFormType
  previous: {
    level: TFormType
    canGoBack: boolean
  }
}

type TFormLevelAddAddressAction = {
  setForm: (level: TFormType, canGoBack?: boolean) => void
  reset: () => void
}

const initialState: TFormLevelAddAddressState = {
  level: "findAddress",
  previous: {
    level: "findAddress",
    canGoBack: true,
  },
}

const useFormLevelAddAddressStore = create<
  TFormLevelAddAddressState & TFormLevelAddAddressAction
>((set) => ({
  ...initialState,
  setForm: (level, canGoBack = true) =>
    set((state) => ({
      previous: {
        level: state.level,
        canGoBack,
      },
      level,
    })),
  reset: () => set(initialState),
}))

export default useFormLevelAddAddressStore
