import authSignOutModule from "./src/authentication/authSignOut"

export {
  clearErrorSession,
  triggeredRefreshToken,
  updateUserSession,
} from "./src/authentication/api"

export * from "./src/authentication/type"

export { clearSession } from "./src/authentication/cookies/sessionStore"

export {
  clearUserInfo,
  getAccountUserInfo,
  getUserInfo,
  getUserInfoCookie,
  getExpiredTimeToken,
} from "./src/authentication/cookies/userInfoStore"

export { default as authConfig } from "./src/authentication/authConfig"
export { default as authCallbacks } from "./src/authentication/authCallbacks"
export { default as authEvent } from "./src/authentication/authEvent"
export { default as authSignIn } from "./src/authentication/authSignIn"
export const { authSignOut } = authSignOutModule
export { default as tokenDecode } from "./src/authentication/helpers/tokenDecode"
export { default as checkIsTokenExpired } from "./src/authentication/helpers/checkIsTokenExpired"
export { default as getSessionToken } from "./src/authentication/cookies/getSessionToken"
