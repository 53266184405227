type TErrorAlertProps = {
  title?: string
  message?: React.ReactNode
}

const ErrorAlert = ({ title, message }: TErrorAlertProps) => {
  return (
    <div className="rounded-2 border-error500 bg-error25 border-y-[1px] border-l-4 border-r-[1px] border-solid p-4">
      <h5 className="text-tertiary500 text-base font-bold">{title}</h5>
      <p className="text-tertiary300 text-sm">{message}</p>
    </div>
  )
}

export default ErrorAlert
