"use client"

import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { useSnackbar } from "shared-ui"

import useFormLevelAddAddressStore from "../../../address/hooks/useFormLevelAddAddressStore"
import { type GetAddressReverseGeocodeQuery } from "../../../federatedGql/graphql"
import {
  GoogleMaps as GoogleMapsV2,
  TErrorCode,
  getErrorSnackbarFromErrCode,
} from "../../../google-v2"
import { type TParamsOnSubmit as TParamsOnSubmitV2 } from "../../../google-v2/google-map/type"
import { federatedGqlClient } from "../../../utils/graphqlClient"
import HeaderForm from "../components/HeaderForm"
import GoogleMaps from "../components/google/google-map"
import { type TParamsOnSubmit } from "../components/google/google-map/type"
import { GET_ADDRESS_REVERSE_GEOCODE } from "../federated/queries"
import { type TFormModalAdd, type TLocationPinPoint } from "../type"

const GoogleMapsSection = ({
  isEdit,
  id = "add-address-googleMaps",
  isUseGoogleMapV2 = false,
}: {
  isEdit?: boolean
  id?: string
  isUseGoogleMapV2?: boolean
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { watch, setValue } = useFormContext<TFormModalAdd>()
  const [latLng, setLatLng] = useState<TLocationPinPoint>()
  const [prevLevelForm, setFormLevel] = useFormLevelAddAddressStore((state) => [
    state.previous,
    state.setForm,
  ])

  const latLngPinPoint = watch("detailAddress.googleMaps.latLng")
  const handleSubmit = (data: TParamsOnSubmit | TParamsOnSubmitV2) => {
    setValue("detailAddress.googleMaps.addressName", data.addressName)
    setValue("detailAddress.googleMaps.addressSecondary", data.addressSecondary)
    setValue("detailAddress.googleMaps.placeId", data.placeId)
    // NOTES: Enable QUERY REVERSE GEOCODE THEN GO TO DETAIL FORM LEVEL
    setLatLng({
      lat: data.lat,
      lng: data.lng,
    })
  }

  const { isFetching } = useQuery<GetAddressReverseGeocodeQuery>({
    queryKey: ["GET_ADDRESS_REVERSE_GEOCODE", latLng],
    queryFn: async ({ queryKey }) => {
      return federatedGqlClient.request(GET_ADDRESS_REVERSE_GEOCODE, {
        lat: (queryKey[1] as TLocationPinPoint).lat,
        lng: (queryKey[1] as TLocationPinPoint).lng,
      })
    },
    onSuccess: (response) => {
      if (latLng) {
        setValue("detailAddress.googleMaps.latLng", latLng)
      }

      if (response.reverseGeocode.__typename === "ReverseGeocode") {
        if (prevLevelForm.level === "manual") {
          setValue(
            "detailAddress.googleMaps.districtAreaCode",
            response.reverseGeocode.districtAreaCode
          )
          setFormLevel("manual")
          return
        }
        setValue("detailAddress.province", {
          name: response.reverseGeocode.provinceName,
          id: response.reverseGeocode.provinceId,
          areaCode: response.reverseGeocode.provinceAreaCode,
        })
        setValue("detailAddress.city", {
          name: response.reverseGeocode.cityName,
          id: response.reverseGeocode.cityId,
          areaCode: response.reverseGeocode.cityAreaCode,
        })
        setValue("detailAddress.district", {
          name: response.reverseGeocode.districtName,
          id: response.reverseGeocode.districtId,
          areaCode: response.reverseGeocode.districtAreaCode,
        })
        setValue("detailAddress.village", {
          name: response.reverseGeocode.villageName,
          id: response.reverseGeocode.villageId,
          areaCode: response.reverseGeocode.villageAreaCode,
        })
        setValue(
          "detailAddress.postalCode.name",
          response.reverseGeocode.villagePostalCode
        )
        setValue(
          "detailAddress.googleMaps.districtAreaCode",
          response.reverseGeocode.districtAreaCode
        )
        setFormLevel("detailAddress")
      } else {
        enqueueSnackbar({
          message: getErrorSnackbarFromErrCode(
            response.reverseGeocode.code as TErrorCode
          ),
          color: "error",
        })
      }
    },
    onError: () => {
      enqueueSnackbar({
        message:
          "Gagal simpan dan lanjutkan, silahkan geser pinpoint lalu coba lagi",
        color: "error",
      })
    },
    enabled: latLng !== undefined,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <HeaderForm
        className="mb-6"
        onClickBack={() =>
          setFormLevel(
            prevLevelForm.canGoBack ? prevLevelForm.level : "findAddress"
          )
        }
        isEdit={isEdit}
        id={id}
      />

      {isUseGoogleMapV2 ? (
        <GoogleMapsV2
          style={{ height: 381 }}
          center={latLngPinPoint}
          onSubmit={handleSubmit}
          loading={isFetching}
          id={id}
        />
      ) : (
        <GoogleMaps
          style={{ height: 381 }}
          center={latLngPinPoint}
          onSubmit={handleSubmit}
          loading={isFetching}
          id={id}
        />
      )}
    </>
  )
}

export default GoogleMapsSection
