import { cx } from "class-variance-authority"
import { AlertTriangle } from "react-feather"

type TErrorCaption = {
  text: string
  type?: "error" | "warning" | "success"
}

const classes = {
  error: "text-primary500",
  warning: "text-warning500",
  success: "text-success500",
}

const ErrorCaption = ({ text, type = "error" }: TErrorCaption) => {
  return (
    <div className="flex flex-nowrap items-center">
      <div className="mr-1 mt-2 h-3 w-3 shrink-0">
        <AlertTriangle className={classes[type]} size={12} />
      </div>
      <div className={cx("text-caption-sm-regular mt-2", classes[type])}>
        {text}
      </div>
    </div>
  )
}

export default ErrorCaption
