import { messageError } from "../constants"
import Link from "next/link"
import { ZENDESK_URL } from "shared-utils"

export type TErrorCode = "InvalidRequest" | "TooManyRequests"

export const getErrorFromErrCode = (errorCode?: TErrorCode) => {
  switch (errorCode) {
    case "TooManyRequests":
      return {
        title: "Pinpoint Mencapai Limit",
        message: (
          <>
            Penggantian pin poin maksimal 30 kali. Silakan coba lagi besok atau
            hubungi{" "}
            <Link
              href={ZENDESK_URL}
              target="_blank"
              className="!text-secondary500 text-sm font-semibold hover:underline"
            >
              Pusat Bantuan.
            </Link>
          </>
        ),
      }
    case "InvalidRequest":
    default:
      return {
        title: "Pinpoint tidak sesuai.",
        message:
          "Pastikan untuk memilih pinpoint yang sesuai dengan kota dan kecamatan Anda.",
      }
  }
}

export const getErrorSnackbarFromErrCode = (errorCode?: TErrorCode) => {
  switch (errorCode) {
    case "TooManyRequests":
      return messageError.HIT_MAX_LIMIT
    case "InvalidRequest":
    default:
      return messageError.DEFAULT
  }
}
