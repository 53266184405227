import GoogleMaps from "./google/google-map/GoogleMapProvider"
import { type TLatLng } from "./google/google-map/type"
import { GoogleMaps as GoogleMapsV2 } from "../../../google-v2"
import { type TLatLng as TLatLngV2 } from "../../../google-v2/google-map/type"

type TGoogleMapFormProps = {
  latLng?: TLatLng | TLatLngV2
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isUseGoogleMapV2?: boolean
}

const GoogleMapForm = ({
  latLng,
  onClick,
  isUseGoogleMapV2,
}: TGoogleMapFormProps) => {
  return (
    <div className="relative mt-2">
      {isUseGoogleMapV2 ? (
        <GoogleMapsV2
          center={latLng}
          isOnlyRender={true}
          style={{ height: 229 }}
        />
      ) : (
        <GoogleMaps
          center={latLng}
          isOnlyRender={true}
          style={{ height: 229 }}
        />
      )}

      <button className="btn-map" onClick={onClick}>
        Atur Lokasi Pinpoint
      </button>
    </div>
  )
}

export default GoogleMapForm
