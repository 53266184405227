"use client"

import dynamic from "next/dynamic"
import Image from "next/image"
import { useState, type ChangeEvent } from "react"
import { CDN_URL } from "shared-config/clientConfig"
import { Button, Switch } from "shared-ui"

import { type SellerBankDetail } from "../../federatedGql/graphql"
import { type TModalUpdateType } from "../types"

const ModalBankStatus = dynamic(() => import("./ModalBankStatus"))

type TBankDetail = {
  sellerBankDetail: SellerBankDetail
}

const BankDetail = ({ sellerBankDetail }: TBankDetail) => {
  const [isActiveModal, setIsActiveModal] = useState<TModalUpdateType>()
  const [isImageError, setIsImageError] = useState<boolean>(false)

  const onClose = () => {
    setIsActiveModal(undefined)
  }

  const handleClickStatus = () => {
    setIsActiveModal("updateBankStatus")
  }

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      handleClickStatus()
    }
  }

  return (
    <>
      <div
        key={sellerBankDetail.id}
        className="rounded-4 border-tertiary50 flex w-full items-center justify-between border border-solid p-4"
      >
        <div className="flex items-center justify-start gap-4">
          <Image
            alt="Logo Bank"
            src={`${CDN_URL}/logo-bank/${
              isImageError ? "placeholder" : sellerBankDetail.bankCode
            }.png`}
            width={80}
            height={80}
            className="!h-fit"
            onError={() => setIsImageError(true)}
          />
          <div className="flex flex-col items-start justify-center gap-1">
            <div className="ml-2 flex flex-col justify-center gap-1">
              <div className="flex items-center gap-1">
                <p className="text-tertiary300 text-caption-lg-regular">
                  {sellerBankDetail.bankName}
                </p>
                {sellerBankDetail.isDefault && sellerBankDetail.isVerify && (
                  <div className="bg-success25 text-success500 text-caption-sm-semibold px-2 py-1">
                    Rekening Utama
                  </div>
                )}
              </div>
              <p className="text-tertiary500 text-caption-lg-bold">
                {sellerBankDetail.accountNo}
              </p>
              <p className="text-tertiary500 text-caption-lg-regular">
                {sellerBankDetail.accountName}
              </p>
            </div>
            {!sellerBankDetail.isDefault && sellerBankDetail.isVerify && (
              <Button
                id="btn-choose-default-account"
                variant="transparent"
                color="primary"
                disabled={!sellerBankDetail.status}
                className="ml-[-10px]"
                onClick={() => console.log("TODO: Integrate main account")}
              >
                Jadikan Rekening Utama
              </Button>
            )}
          </div>
        </div>
        <div className="flex h-full items-center justify-center gap-6">
          {!sellerBankDetail.isDefault && sellerBankDetail.isVerify && (
            <Switch
              id="btn-switch-active-bank-account"
              checked={!!sellerBankDetail.status}
              onChange={handleSwitchChange}
            >{`${sellerBankDetail.status ? "Aktif" : "Tidak Aktif"}`}</Switch>
          )}
          <Button
            id="btn-delete-bank-account"
            variant="transparentAccent"
            color="primary"
            onClick={() => console.log("TODO: Delete account")}
          >
            Hapus
          </Button>
        </div>
      </div>
      {isActiveModal === "updateBankStatus" && (
        <ModalBankStatus
          isShow={isActiveModal === "updateBankStatus"}
          onClose={onClose}
          sellerBankDetail={sellerBankDetail}
        />
      )}
    </>
  )
}

export default BankDetail
