"use client"

import { GoogleMap } from "@react-google-maps/api"
import { cx } from "class-variance-authority"
import { useCallback, useEffect, useRef, useState, memo } from "react"
import { Search } from "react-feather"
import { Button } from "shared-ui"
import { useSnackbar } from "shared-ui"

import CurrentLocation from "./components/CurrentLocation"
import MapsSkeletonLoading from "./components/MapsSkeletonLoading"
import MarkerIcon from "./components/MarkerIcon"
import NotFoundAlert from "./components/NotFoundAlert"

import { type TGoogleMaps, type TLatLng } from "./type"
import { checkLocationPermission } from "../../../../../address/utils/checkLocationPermission"
import { formatAddressGoogleMaps } from "../../../../../address/utils/formatAddressGoogleMaps"
import { getAddressFromLatLng } from "../../../../../address/utils/getAddressFromLatLng"
import {
  defaultLatLng,
  defaultZoom,
  mapContainerStyle,
  messageError,
  optionsGoogleMap,
} from "../constants"
import GoogleSearchAutoComplete, {
  type TResultGoogleSearchAutoComplete,
} from "../google-search-autocomplete"
import "./style.css"

const GoogleMaps = ({
  center,
  onSubmit,
  loading: loadingProps,
  isOnlyRender,
  style,
  id,
  addressSectionClassName,
  submitBtnClassName,
}: TGoogleMaps) => {
  const refMap = useRef<google.maps.Map>()
  const [latLng, setLatLng] = useState<TLatLng>(defaultLatLng)
  const [address, setAddress] = useState({
    name: "",
    secondary: "",
    placeId: "",
  })
  const [isValidLocation, setIsValidLocation] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const isDisableButton =
    !latLng || isLoading || loadingProps || !isValidLocation

  // NOTES: HANDLE GEOCODING
  const getAddressFromGeocode = useCallback(async (location: TLatLng) => {
    try {
      const result = await getAddressFromLatLng({
        location,
      })

      const { placeId, addressName, addressSecondary, isCountryValid } =
        formatAddressGoogleMaps({
          addressComponent: (result as google.maps.GeocoderResult)
            .address_components,
          placeId: (result as google.maps.GeocoderResult).place_id,
        })
      setIsValidLocation(isCountryValid)
      setAddress({
        name: addressName,
        secondary: addressSecondary,
        placeId,
      })
      setLatLng(location)
    } catch {
      setIsValidLocation(false)
    } finally {
      setTimeout(() => setIsLoading(false), 400)
    }
  }, [])

  // NOTES: FOR SET LAT LNG FROM PROPS, THIS SHOULD EXECUTE ONCE (FIRST TIME)
  useEffect(() => {
    if (center) {
      setLatLng(center)
    }
    setIsLoading(true)
    void getAddressFromGeocode(center || defaultLatLng)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDragEnd = useCallback(() => {
    if (!refMap) return

    const refTemp = refMap?.current as google.maps.MapOptions

    const centered = {
      lat: (refTemp?.center as google.maps.LatLng)?.lat(),
      lng: (refTemp?.center as google.maps.LatLng)?.lng(),
    }
    void getAddressFromGeocode(centered)
  }, [getAddressFromGeocode])

  const handleRequestLocation = async () => {
    await checkLocationPermission({
      isOnlyRequestPermission: false,
      callbackFn: ({ type, latLng: locationLatLng }) => {
        if (type === "DENIED" || type === "INVALID") {
          return enqueueSnackbar({
            message: messageError[type],
            type: "error",
          })
        }

        if (locationLatLng) {
          void getAddressFromGeocode(locationLatLng)
        }
      },
    })
  }

  const handleOnSelectSearch = useCallback(
    ({
      addressName,
      addressSecondary,
      lat,
      lng,
      placeId,
    }: TResultGoogleSearchAutoComplete) => {
      setAddress({
        name: addressName,
        secondary: addressSecondary,
        placeId,
      })
      setLatLng({
        lat,
        lng,
      })
      setIsValidLocation(true)
    },
    []
  )

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      refMap.current = mapInstance
    },
    [refMap]
  )

  const onUnmount = useCallback(() => {
    refMap.current = undefined
  }, [])

  if (isOnlyRender) {
    return (
      <GoogleMap
        mapContainerStyle={{ ...mapContainerStyle, ...style }}
        center={latLng || defaultLatLng}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={optionsGoogleMap}
        zoom={defaultZoom}
      >
        <div className="blockingTag" />
        <MarkerIcon />
      </GoogleMap>
    )
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ ...mapContainerStyle, ...style }}
        center={latLng || defaultLatLng}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDragStart={() => setIsLoading(true)}
        onDragEnd={handleDragEnd}
        options={optionsGoogleMap}
        zoom={defaultZoom}
      >
        <div className="headerContainer flex gap-x-4">
          <CurrentLocation
            onClickCurrentLocation={handleRequestLocation}
            id={id}
          />
          <div className="w-full max-w-[368px]">
            <GoogleSearchAutoComplete
              id={id}
              onSelect={handleOnSelectSearch}
              placeholder="Cari ulang alamat"
              iconLeft={<Search color="#344054" width={20} height={20} />}
              iconStyle={{
                iconLeft: { padding: "0px 14px" },
              }}
              isClearFieldOnBlur={true}
              withChevron={false}
            />
          </div>
        </div>
        {!isLoading && (
          <div className="tooltip-container">
            <div className="tooltip text-primary25 text-sm">
              Alamat Anda di sini
            </div>
          </div>
        )}
        <MarkerIcon />
      </GoogleMap>
      <div className={cx("py-4", addressSectionClassName)}>
        {isLoading ? (
          <MapsSkeletonLoading />
        ) : !isValidLocation ? (
          <NotFoundAlert />
        ) : (
          <>
            <h5 className="text-tertiary500 pb-2 text-base font-bold">
              {address.name}
            </h5>
            <p className="text-tertiary300 text-sm">{address.secondary}</p>
          </>
        )}
      </div>
      <div className={cx(submitBtnClassName, "mt-6")}>
        <Button
          id={`${id}-save-pinpoint`}
          className="w-full"
          onClick={() => {
            if (!latLng) return
            onSubmit &&
              onSubmit({
                ...latLng,
                addressName: address.name,
                addressSecondary: address.secondary,
                placeId: address.placeId,
              })
          }}
          disabled={isDisableButton}
          isLoading={loadingProps}
        >
          Pilih Lokasi & Lanjut Isi Alamat
        </Button>
      </div>
    </>
  )
}

export default memo(GoogleMaps)
