"use client"

import { useState, type CSSProperties } from "react"

import InputSelect from "../input-select"
import { type TOption, type TInputSelectProps } from "../input-select/type"
import useGetAutocomplete from "../hooks/useGetAutocomplete"
import {
  getPlaceDetail,
  TErrorCode,
  getErrorSnackbarFromErrCode,
} from "../utils"
import { useSnackbar } from "shared-ui"
import { messageError } from "../constants"

export type TResultGoogleSearchAutoComplete = {
  addressName: string
  addressSecondary: string
  placeId: string
  lat: number
  lng: number
}

type TGoogleSearchAutoCompleteProps = Omit<TInputSelectProps, "onSelect"> & {
  onSelect: (data: TResultGoogleSearchAutoComplete) => void
  iconStyle?: {
    iconLeft?: CSSProperties
    iconRight?: CSSProperties
  }
  withChevron?: boolean
}

const GoogleSearchAutoComplete = ({
  onSelect,
  onClickCurrentLocation,
  onClickManualLocation,
  placeholder,
  iconLeft,
  iconRight,
  isClearFieldOnBlur,
  id,
  iconStyle,
  withChevron,
  disabled,
}: TGoogleSearchAutoCompleteProps) => {
  const { data: options, setInput, isFetching } = useGetAutocomplete()
  const { enqueueSnackbar } = useSnackbar()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isLoadingSelect, setIsLoadingSelect] = useState<boolean>(false)

  const handleSelectLocation = async (newValue: unknown) => {
    try {
      setIsLoadingSelect(true)
      const { data, error } = await getPlaceDetail({
        placeId: (newValue as TOption).value,
      })
      if (error || !data) {
        enqueueSnackbar({
          message: getErrorSnackbarFromErrCode(error as TErrorCode),
          type: "error",
        })
        return
      }
      onSelect(data)
    } catch {
      enqueueSnackbar({
        message: messageError.DEFAULT,
        type: "error",
      })
    } finally {
      setIsLoadingSelect(false)
    }
  }

  return (
    <div
      className={
        !!options?.length && isMenuOpen
          ? "h-28"
          : isMenuOpen
          ? "h-21"
          : "h-auto"
      }
    >
      <InputSelect
        id={id}
        onChange={(input) => setInput(input)}
        options={options}
        onFocus={() => setIsMenuOpen(true)}
        onSelect={handleSelectLocation}
        onBlur={() => setIsMenuOpen(false)}
        isLoading={isFetching}
        onClickCurrentLocation={onClickCurrentLocation}
        onClickManualLocation={onClickManualLocation}
        placeholder={placeholder}
        iconLeft={iconLeft}
        iconRight={iconRight}
        isClearFieldOnBlur={isClearFieldOnBlur}
        iconStyle={iconStyle}
        withChevron={withChevron}
        disabled={isLoadingSelect || disabled}
      />
    </div>
  )
}

export default GoogleSearchAutoComplete
