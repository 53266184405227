import { create } from "zustand"
import { devtools } from "zustand/middleware"

type DialogAuthTypes = {
  description?: string
  title?: string
}
interface IDialogAuthStore {
  description: string
  title: string
  setDialogAuthData: ({ description, title }: DialogAuthTypes) => void
  isOpen: boolean
  onClose: () => void
  openDialogAuth: () => void
}

const defaultDialog = {
  description:
    "Anda perlu login terlebih dahulu, untuk melanjutkan proses tersebut",
  title: "Silakan Login",
}

export const useDialogAuth = create<IDialogAuthStore>()(
  devtools(
    (set) => ({
      description: defaultDialog.description,
      title: defaultDialog.title,
      isOpen: false,
      onClose: () => set(() => ({ isOpen: false })),
      setDialogAuthData: ({
        description = defaultDialog.description,
        title = defaultDialog.title,
      }) =>
        set(() => ({
          description,
          title,
        })),
      openDialogAuth: () => set(() => ({ isOpen: true })),
    }),
    { name: "dialog-auth-store" }
  )
)
