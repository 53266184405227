import { TErrorCode, getErrorFromErrCode } from "../../utils"
import ErrorAlert from "./ErrorAlert"
import MapsSkeletonLoading from "./MapsSkeletonLoading"

type TAddressSection = {
  isLoading?: boolean
  errorCode?: TErrorCode
  address: {
    name: string
    secondary: string
    placeId: string
  }
}

const AddressSection = ({ isLoading, errorCode, address }: TAddressSection) => {
  if (isLoading) {
    return <MapsSkeletonLoading />
  }

  if (errorCode) {
    return <ErrorAlert {...getErrorFromErrCode(errorCode)} />
  }

  return (
    <>
      <h5 className="text-tertiary500 pb-2 text-base font-bold">
        {address.name}
      </h5>
      <p className="text-tertiary300 text-sm">{address.secondary}</p>
    </>
  )
}

export default AddressSection
