import { ChevronRight } from "react-feather"
import { cx } from "class-variance-authority"

const CurrentLocation = ({
  onClickCurrentLocation,
  id,
  disabled,
}: {
  onClickCurrentLocation: () => void
  id?: string
  disabled?: boolean
}) => {
  return (
    <button
      id={`${id}-currentLocation`}
      className="rounded-4 bg-primary25 disabled:text-tertiary200 disabled:bg-tertiary25 inline-flex h-fit items-center gap-x-4 whitespace-nowrap px-3 py-2"
      type="button"
      disabled={disabled}
      onClick={onClickCurrentLocation}
    >
      <p
        className={cx(
          "text-sm font-semibold",
          disabled ? "text-tertiary200" : "text-tertiary500"
        )}
      >
        Gunakan Lokasi Saat Ini
      </p>{" "}
      <ChevronRight size={18} />
    </button>
  )
}

export default CurrentLocation
