import { Skeleton } from "shared-ui"

const MapsSkeletonLoading = () => {
  return (
    <>
      <Skeleton
        bgColor="bg-tertiary50"
        width="w-2/5"
        height="h-5"
        className="mb-2"
      />
      <Skeleton bgColor="bg-tertiary50" width="w-3/5" height="h-5" />
    </>
  )
}

export default MapsSkeletonLoading
