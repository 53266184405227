import { useQuery } from "@tanstack/react-query"
import { GET_AUTOCOMPLETE } from "../federated/queries"
import { useState } from "react"
import { useDebounce } from "shared-utils"
import { federatedGqlClient } from "../../utils/graphqlClient"
import { useSnackbar } from "shared-ui"
import { messageError } from "../constants"
import { TErrorCode, getErrorSnackbarFromErrCode } from "../utils"

export default function useGetAutocomplete() {
  const [input, setInput] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const debounceValue = useDebounce(input, 2000)

  const query = useQuery({
    queryKey: ["get_autocomplete", debounceValue],
    queryFn: async () => {
      try {
        const result = await federatedGqlClient.request(GET_AUTOCOMPLETE, {
          input: debounceValue,
        })

        if (result.autocomplete.__typename === "GenericError") {
          enqueueSnackbar({
            message: getErrorSnackbarFromErrCode(
              result.autocomplete.code as TErrorCode
            ),
            type: "error",
          })
          return []
        }

        return result.autocomplete.result?.map((item) => ({
          label: item?.mainText ?? "",
          desc: item?.secondaryText ?? "",
          value: item?.placeId ?? "",
          areaCode: "",
        }))
      } catch {
        enqueueSnackbar({
          message: messageError.DEFAULT,
          type: "error",
        })
        return []
      }
    },
    initialData: [],
    enabled: !!debounceValue,
    retry: 1,
    cacheTime: 0,
  })

  return {
    setInput,
    ...query,
  }
}
