import { LoadScriptNext } from "@react-google-maps/api"
import { NEXT_PUBLIC_GOOGLE_API_KEY } from "shared-config/clientConfig"

import GoogleMap from "."
import { type TGoogleMaps } from "./type"

const GoogleMapProvider = (props: TGoogleMaps) => {
  return (
    <LoadScriptNext
      googleMapsApiKey={NEXT_PUBLIC_GOOGLE_API_KEY}
      language="id"
      libraries={["places", "geometry"]}
    >
      <GoogleMap {...props} />
    </LoadScriptNext>
  )
}

export default GoogleMapProvider
