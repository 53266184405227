import { MapPin } from "react-feather"
import { useFormContext } from "react-hook-form"
import { Button, Checkbox, Spinner } from "shared-ui"

import FormRegionAutoSuggest from "./FormRegionAutoSuggest"
import useFormLevelAddAddressStore from "../../hooks/useFormLevelAddAddressStore"
import GoogleMapForm from "../components/GoogleMapForm"
import HeaderForm from "../components/HeaderForm"
import BadgeLabel from "../components/badge-label"
import { InputField, InputFieldArea } from "../components/input"
import { type TFormModalAdd } from "../type"
import "../../style.css"

type TManualFormAddressSectionProps = {
  handleSubmit: (address: TFormModalAdd) => void
  onClickCancel: (val: boolean) => void
  loading?: boolean
  id?: string
  isUseGoogleMapV2?: boolean
}

const ManualFormAddressSection = ({
  handleSubmit,
  onClickCancel,
  loading,
  id = "add-address-manualAddress",
  isUseGoogleMapV2 = false,
}: TManualFormAddressSectionProps) => {
  const {
    setValue,
    handleSubmit: onSubmitHookForm,
    watch,
    control,
    formState: { errors },
    clearErrors,
    reset,
  } = useFormContext<TFormModalAdd>()

  const [latLng, isPrimaryAddress] = watch([
    "detailAddress.googleMaps.latLng",
    "detailAddress.isPrimaryAddress",
  ])

  const setFormLevel = useFormLevelAddAddressStore((state) => state.setForm)

  return (
    <>
      <HeaderForm
        className="mb-10"
        onClickBack={() => {
          setFormLevel("findAddress")
          clearErrors()
          reset()
        }}
        id={id}
      />
      <h5 className="text-tertiary500 mb-4 text-base font-bold">
        Lengkapi Detail Alamat
      </h5>
      <InputField
        id={`${id}-input-label`}
        label="Label Alamat"
        control={control}
        name="detailAddress.label"
        placeholder="Contoh: rumah, apartemen, kantor, dan lainnya"
        errorMessage={errors.detailAddress?.label?.message}
      />
      <InputField
        id={`${id}-input-name`}
        label="Nama Penerima"
        control={control}
        name="detailAddress.name"
        placeholder="Masukkan nama penerima"
        errorMessage={errors.detailAddress?.name?.message}
      />
      <InputField
        id={`${id}-input-phone`}
        label="Nomor Telepon Penerima"
        control={control}
        name="detailAddress.phone"
        placeholder="08xx-xxxx-xxxx"
        errorMessage={errors.detailAddress?.phone?.message}
      />
      <FormRegionAutoSuggest />
      <fieldset className="pb-4">
        <div className="flex items-center gap-2">
          <label className="text-tertiary500 text-sm font-semibold">
            Atur Lokasi
          </label>
          <BadgeLabel text="Tidak Wajib" />
        </div>
        {latLng ? (
          <GoogleMapForm
            latLng={latLng}
            onClick={(e) => {
              e.preventDefault()
              setFormLevel("googleMaps")
            }}
            isUseGoogleMapV2={isUseGoogleMapV2}
          />
        ) : (
          <button
            id={`${id}-btn-set-pinpoint`}
            className="rounded-4 text-tertiary200 bg-tertiary25 mt-2 flex min-h-[40px] w-full items-center justify-center gap-x-2 py-3"
            onClick={(e) => {
              e.preventDefault()
              setFormLevel("googleMaps")
            }}
          >
            <span>
              <MapPin />
            </span>
            Atur Lokasi Pinpoint
          </button>
        )}
      </fieldset>
      <fieldset className="mb-2">
        <InputFieldArea
          id={`${id}-input-address`}
          control={control}
          name="detailAddress.address"
          label="Alamat Lengkap"
          maxLength={100}
          placeholder="Masukkan nama jalan, nomor rumah atau kantor, dan lainnya."
          errorMessage={errors.detailAddress?.address?.message}
        />
      </fieldset>
      <InputField
        id={`${id}-input-notes`}
        label="Catatan"
        control={control}
        name="detailAddress.notes"
        placeholder="Warna rumah, patokan, pesanan khusus dan lainnya."
        labelOptional
        errorMessage={errors.detailAddress?.notes?.message}
      />
      <fieldset>
        <Checkbox
          id={`${id}-checkbox-mainAddress`}
          label="Jadikan sebagai alamat utama"
          onChange={(e) =>
            setValue("detailAddress.isPrimaryAddress", e.currentTarget.checked)
          }
          checked={isPrimaryAddress}
        />
      </fieldset>
      <fieldset className="mb-3 mt-10 flex gap-x-4">
        <Button
          id={`${id}-btn-cancel`}
          variant="outline2"
          className="w-full"
          onClick={() => onClickCancel(true)}
        >
          Batal
        </Button>
        <Button
          id={`${id}-btn-save`}
          className="w-full"
          onClick={(e) => void onSubmitHookForm(handleSubmit)(e)}
          disabled={loading}
          IconRight={() =>
            loading ? <Spinner color="primary" size="small" /> : <></>
          }
        >
          Simpan
        </Button>
      </fieldset>
    </>
  )
}

export default ManualFormAddressSection
