import { Button, Modal } from "shared-ui"
import { useSnackbar } from "shared-ui"

import { type SellerBankDetail } from "../../federatedGql/graphql"

type TModalBankStatus = {
  isShow: boolean
  onClose: () => void
  sellerBankDetail: SellerBankDetail
}

const ModalBankStatus = ({
  isShow,
  onClose,
  sellerBankDetail,
}: TModalBankStatus) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleOnClose = () => {
    onClose()
  }

  const handleOnConfirm = () => {
    // TODO: Integrate with BE
    enqueueSnackbar({
      message: `Berhasil ${
        sellerBankDetail.status ? "nonaktifkan" : "aktifkan"
      } ${sellerBankDetail.bankName}.`,
      type: "success",
    })
    onClose()
  }

  return (
    <div>
      <Modal
        modalId="modal-update-bank-status"
        open={isShow}
        title={`${
          sellerBankDetail.status ? "Nonaktifkan" : "Aktifkan"
        } rekening ${sellerBankDetail.bankName}?`}
        onOpenChange={handleOnClose}
        closable={false}
        classNames={{
          content: "w-[528px]",
        }}
      >
        {sellerBankDetail.status ? (
          <p className="text-tertiary300 text-caption-lg-regular mb-6 mt-4">
            Jika{" "}
            <span className="font-semibold">
              {`${sellerBankDetail.bankName} ${sellerBankDetail.bankCode} a.n. ${sellerBankDetail.accountName}`}
            </span>{" "}
            ingin dijadikan rekening utama lagi, Anda harus mengaktifkan ulang
            rekening di akun ini.
          </p>
        ) : (
          <p className="text-tertiary300 text-caption-lg-regular mb-6 mt-4">
            Anda akan mengaktifkan kembali{" "}
            <span className="font-semibold">{`${sellerBankDetail.bankName} ${sellerBankDetail.bankCode} a.n. ${sellerBankDetail.accountName}`}</span>
          </p>
        )}

        <div className="mt-6 flex justify-between gap-3">
          <Button
            id="btn-cancel-update-bank-status"
            variant="outline2"
            color="tertiary"
            className="bg-primary25 w-full font-light"
            onClick={handleOnClose}
          >
            Batal
          </Button>
          <Button
            id="btn-confirm-update-bank-status"
            variant="solid"
            color="primary"
            className="bg-primary500 w-full font-light"
            onClick={handleOnConfirm}
          >
            {`Ya, ${sellerBankDetail.status ? "Nonaktifkan" : "Aktifkan"}`}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default ModalBankStatus
