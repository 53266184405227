import Image from "next/image"
import { ArrowLeft, Mail } from "react-feather"
import WhatsappIcon from "shared-assets/icons/whatsapp-icon.webp"
import { Button } from "shared-ui"

const MethodVerification = () => {
  return (
    <div className="bg-primary25 rounded-4 flex w-2/5 items-center justify-center">
      <div className="p-8">
        <div className="mb-[10px]">
          <ArrowLeft
            id="btn-back-method-verification"
            size={24}
            className="mr-3 cursor-pointer"
          />
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <div className="text-tertiary500 text-2xl font-bold">
              Pilih Metode Verifikasi
            </div>
            <div className="text-tertiary300 mt-2 !w-[328px] text-center text-sm">
              Lakukan verifikasi untuk menambahkan bank di Account Anda. Pilih
              salah satu metode di bawah ini untuk mendapatkan kode verifikasi.
            </div>
          </div>
          <Button
            className="!rounded-4 mt-9 w-full !px-4 !py-[14px] text-base font-semibold"
            variant="outline2"
            IconLeft={Mail}
            id="method-verification-email-bank-otp"
            onClick={() => console.log("TODO: Integrate Email Verification")}
          >
            Email ke govmart@mail.com
          </Button>
          <Button
            className="!rounded-4 mt-4 w-full !px-4 !py-[14px] text-base font-semibold"
            variant="outline2"
            IconLeft={() => (
              <Image
                src={WhatsappIcon}
                alt="Whatsapp Icon"
                className="mr-2"
                width={20}
                height={20}
              />
            )}
            id="method-verification-whatsapp-bank-otp"
            onClick={() => console.log("TODO: Integrate OTP Verification")}
          >
            Whatsapp
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MethodVerification
