import { cx } from "class-variance-authority"
import { type TextareaHTMLAttributes } from "react"
import { AlertTriangle } from "react-feather"
import { type Control, type FieldPath, useController } from "react-hook-form"

import { type TFormModalAdd } from "../../type"
import BadgeLabel from "../badge-label"

export type TInputFieldArea = {
  errorMessage?: string
  containerClassName?: string
  name: FieldPath<TFormModalAdd>
  control: Control<TFormModalAdd>
  label: string
  width?: string | number
  labelOptional?: boolean
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "value" | "name">

const InputFieldArea = ({
  id,
  name,
  className,
  placeholder,
  errorMessage,
  width,
  disabled,
  autoFocus,
  containerClassName,
  control,
  label,
  maxLength,
  rows = 3,
  labelOptional,
  ...rest
}: TInputFieldArea) => {
  const {
    field: { onChange, value },
  } = useController({
    name: name,
    control: control,
  })

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-2">
        <label className=" text-tertiary500 text-sm font-semibold">
          {label}
        </label>
        {!!labelOptional && <BadgeLabel text="Tidak Wajib" />}
      </div>

      <div
        className={cx(
          "rounded-4 focus-within:border-tertiary200 flex items-center border",
          disabled
            ? "border-tertiary200"
            : !!errorMessage
            ? "border-error500"
            : "border-tertiary50",
          containerClassName ? containerClassName : ""
        )}
      >
        <textarea
          {...rest}
          value={value?.toString()}
          id={id}
          rows={rows}
          name={name}
          className={cx(
            "rounded-4 text-tertiary500 disabled:text-tertiary200 flex-grow border-none px-3 py-2 text-sm font-normal outline-none disabled:border-[#A8ADB7] disabled:bg-[#F5F6F9]",
            className ? className : "",
            width ? width : "w-full"
          )}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          style={!!errorMessage ? { background: "none" } : undefined}
          maxLength={maxLength}
        />
      </div>
      <div
        className={`flex items-center ${
          !!errorMessage ? "justify-between" : "justify-end"
        }`}
        style={{ gap: "56px" }}
      >
        {!!errorMessage && (
          <div className="rounded-l-4 left-0 top-0 mt-2 flex items-center justify-center">
            <AlertTriangle
              size={14}
              color="#F04438"
              className="relative mr-1"
            />
            <div className="text-error500 text-xs">{errorMessage}</div>
          </div>
        )}
        <div
          className={`text-sm font-normal ${
            errorMessage ? "text-error500" : "text-grey"
          }`}
        >
          {value?.toString().length || 0}/{maxLength}
        </div>
      </div>
    </div>
  )
}
export default InputFieldArea
