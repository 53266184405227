import { setCookie } from "cookies-next"
import {
  signIn as signInService,
  signOut as signOutService,
} from "next-auth/react"
import {
  authSignOut,
  authSignIn,
  type TProvider,
} from "shared-utils/authentication"

import { AUTH } from "@/config/api"
import { AUTH as PUBLIC } from "@/config/client"

export const isFirstSignIn = "___isFirstSignIn"

export const signOut = (redirect?: boolean) => {
  setCookie(isFirstSignIn, true)
  localStorage.removeItem("search-history-storage")
  localStorage.removeItem("alert-tte-storage")

  return signOutService({
    redirect,
  }).finally(() =>
    authSignOut({ domain: AUTH.DOMAIN, baseUrl: PUBLIC.BASE_URL })
  )
}

type TSignIn = {
  callbackUrl?: string
}

export const signIn = async (provider: TProvider, props?: TSignIn) => {
  setCookie(isFirstSignIn, true)
  const signInOptions = await authSignIn(provider, {
    ...props,
    baseUrl: PUBLIC.BASE_URL,
    domain: AUTH.DOMAIN,
  })
  if (signInOptions) {
    signInService("auth0", { ...signInOptions })
  }
}
