import { graphql } from "../../federatedGql"

export const GET_AUTOCOMPLETE = graphql(`
  query getAutocomplete($input: String!) {
    autocomplete(input: { input: $input }) {
      ... on Autocomplete {
        __typename
        result {
          mainText
          placeId
          secondaryText
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_ADDRESS_FROM_GEOCODE = graphql(`
  query getAddressFromGeocode(
    $latitude: Float!
    $longitude: Float!
    $language: String!
  ) {
    geocode(
      input: { latitude: $latitude, longitude: $longitude, language: $language }
    ) {
      ... on Geocode {
        __typename
        result {
          types
          placeId
          addressComponents {
            longName
            shortName
            types
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PLACE_DETAIL = graphql(`
  query getPlaceDetail($placeId: String!) {
    placeDetails(input: { placeId: $placeId }) {
      ... on PlaceDetails {
        __typename
        result {
          location {
            lat
            lng
          }
          addressComponents {
            longName
            types
            shortName
          }
          placeId
          types
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
