const NotFoundAlert = () => {
  return (
    <div className="rounded-2 border-error500 bg-error25 border-y-[1px] border-l-4 border-r-[1px] border-solid p-4">
      <h5 className="text-tertiary500 text-base font-bold">
        Pinpoint tidak sesuai.
      </h5>
      <p className="text-tertiary300">
        Pastikan untuk memilih pinpoint yang sesuai dengan kota dan kecamatan
        Anda.
      </p>
    </div>
  )
}

export default NotFoundAlert
