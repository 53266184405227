import { GET_PLACE_DETAIL } from "../federated/queries"
import { federatedGqlClient } from "../../utils/graphqlClient"
import { formatAddressGoogleMapsV2 } from "./formatAddressGoogleMapsV2"
import { GenericExtractor } from "shared-utils"
import { GetPlaceDetailQuery } from "../../federatedGql/graphql"

export type TResultGetPlaceDetailQuery = GenericExtractor<
  GetPlaceDetailQuery["placeDetails"]
>

export default async function getPlaceDetail({ placeId }: { placeId: string }) {
  try {
    const result = await federatedGqlClient.request(GET_PLACE_DETAIL, {
      placeId,
    })

    if (result.placeDetails.__typename === "GenericError") {
      return {
        error: result.placeDetails.code,
      }
    }

    const {
      placeId: formattedPlaceId,
      addressName,
      addressSecondary,
    } = formatAddressGoogleMapsV2({
      addressComponent: result.placeDetails.result.addressComponents,
      placeId: result.placeDetails.result.placeId,
    })
    return {
      data: {
        addressName,
        addressSecondary,
        placeId: formattedPlaceId,
        lat: result.placeDetails.result.location.lat,
        lng: result.placeDetails.result.location.lng,
      },
    }
  } catch {
    return { error: "InvalidRequest" }
  }
}
