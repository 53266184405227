export const optionsGoogleSearch = {
  region: "id",
  input: "",
  componentRestrictions: {
    country: "id",
  },
}

export const mapContainerStyle = {
  height: "100%",
  width: "100%",
  display: "relative",
}

export const optionsGoogleMap = {
  disableDefaultUI: true,
  zoomControl: false,
}

export const defaultZoom = 14

export const defaultLatLng = {
  lat: -6.175380828589962, // monas's lat
  lng: 106.82719334025876, // monas's lng
}

export const messageError = {
  DENIED:
    "Akses Lokasi terblokir, silahkan mengaktifkan di setting browser anda",
  INVALID: "Error: Fitur Akses lokasi saat ini tidak tersedia di browser anda",
  HIT_MAX_LIMIT:
    "Penggantian pin poin maksimal 30 kali. Silakan coba lagi besok atau hubungi Pusat Bantuan.",
  DEFAULT: "Error: Silahkan coba lagi nanti atau hubungi Pusat Bantuan.",
}
