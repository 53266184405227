import { GetAddressFromGeocodeQuery } from "../../federatedGql/graphql"
import { GET_ADDRESS_FROM_GEOCODE } from "../federated/queries"
import { federatedGqlClient } from "../../utils/graphqlClient"
import { GenericExtractor } from "shared-utils"

type TLatLng = { lat: number; lng: number }

type GoogleServiceProps = {
  location: TLatLng
}

export type TResultGetAddressFromGeocodeQuery = GenericExtractor<
  GetAddressFromGeocodeQuery["geocode"]
>

export const getAddressFromGeocode = async ({
  location,
}: GoogleServiceProps) => {
  try {
    const { lat: latitude, lng: longitude } = location
    const res = await federatedGqlClient.request(GET_ADDRESS_FROM_GEOCODE, {
      language: "id",
      latitude,
      longitude,
    })

    if (res.geocode.__typename === "GenericError") {
      return {
        error: res.geocode.code,
      }
    }

    return {
      result: res?.geocode?.result?.find(
        (item) =>
          item.types?.includes("administrative_area_level_4") ||
          item.types?.includes("postal_code")
      ),
    }
  } catch (error) {
    return {
      error: "InvalidRequest",
    }
  }
}
