import Image from "next/image"
import CurrentLocation from "shared-assets/icons/current-location.webp"

import { type TInputSelectProps } from "../type"

const HeaderMenu = ({
  onClickCurrentLocation,
  id,
}: Pick<
  TInputSelectProps,
  "onClickCurrentLocation" | "onClickManualLocation" | "id"
>) => {
  return (
    <section className="border-b-tertiary25 sticky left-0 top-0 z-50 w-full border-b-2 p-4">
      <div
        className="inline-flex cursor-pointer items-center justify-center gap-x-4"
        onClick={onClickCurrentLocation}
        tabIndex={0}
        id={`${id}-currentLocation`}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Image
          src={CurrentLocation}
          alt="current-location"
          width={24}
          height={24}
        />
        <p className="text-tertiary500 text-sm">Gunakan Lokasi Saat Ini</p>
      </div>
    </section>
  )
}

export default HeaderMenu
