import { ChevronRight } from "react-feather"

const CurrentLocation = ({
  onClickCurrentLocation,
  id,
}: {
  onClickCurrentLocation: () => void
  id?: string
}) => {
  return (
    <button
      id={`${id}-currentLocation`}
      className="rounded-4 bg-primary25 inline-flex h-fit items-center gap-x-4 whitespace-nowrap px-3 py-2"
      type="button"
      onClick={onClickCurrentLocation}
    >
      <p className="text-tertiary500 text-sm font-semibold">
        Gunakan Lokasi Saat Ini
      </p>{" "}
      <ChevronRight size={18} />
    </button>
  )
}

export default CurrentLocation
