type TSignOut = {
  domain: string
  baseUrl: string
  toSignOutPage?: boolean
}

const authSignOut = ({ domain, baseUrl, toSignOutPage }: TSignOut) => {
  const url = `${domain}/logout?returnTo=${baseUrl}${
    toSignOutPage ? "auth/signout" : ""
  }`
  window?.location?.replace(url)
  return url
}

export default {
  authSignOut,
}
