import { cx } from "class-variance-authority"
import dynamic from "next/dynamic"
import { MapPin } from "react-feather"
import { Button, type ModalProps } from "shared-ui"

import ErrorCaption from "./ErrorCaption"
import { type TGoogleMaps, type TLatLng } from "../google/google-map/type"

const ModalGoogleMap = dynamic(() => import("./ModalGoogleMap"))

type TPinpointCard = {
  isShowGoogleMapV2?: boolean
  errorMsg?: string
  id?: string
  toggleModal: () => void
  address: {
    villageName?: string
    cityName?: string
    districtName?: string
    provinceName?: string
    latLng?: Partial<TLatLng>
  }
} & TGoogleMaps &
  Pick<ModalProps, "classNames" | "open">

const PinpointCard = ({
  errorMsg,
  id,
  classNames = { content: "w-[662px] h-[581px] !px-0" },
  toggleModal,
  open,
  address,
  isShowGoogleMapV2,
  ...props
}: TPinpointCard) => {
  const renderPinpointLabel = (text: string) => {
    return (
      <div className="flex items-center">
        <MapPin width={20} height={20} className="pr-1" />
        <span className="text-caption-sm-semibold">{text}</span>
      </div>
    )
  }

  const renderCardContent = () => {
    if (!address.latLng) {
      return (
        <>
          <span className="text-caption-lg-regular ">
            Tambahkan pinpoint untuk lokasi yang lebih akurat
          </span>
          <Button
            IconLeft={MapPin}
            variant="outline1"
            id="set-pinpoint-btn"
            size="large"
            className="my-3"
            onClick={toggleModal}
            type="button"
          >
            Atur Lokasi Pinpoint
          </Button>
          {renderPinpointLabel("Belum ditandai pinpoint")}
        </>
      )
    }
    return (
      <div className="flex items-center">
        <div className="flex-grow pr-2">
          <h3 className="text-body-sm-bold text-tertiary500">
            {address.provinceName}
          </h3>
          <span className="text-caption-lg-regular my-2 block">
            {address.districtName}, {address.cityName}, {address.provinceName}
          </span>
          {renderPinpointLabel("Sudah ditandai pinpoint")}
        </div>
        <Button
          id="change-pinpoint-btn"
          type="button"
          className="flex-shrink-0"
          onClick={toggleModal}
          variant="transparent"
        >
          Ubah Pinpoint
        </Button>
      </div>
    )
  }

  return (
    <>
      <div
        className={cx(
          errorMsg ? "border-error500" : " border-tertiary50",
          "rounded-4 text-tertiary300 w-[575px] border p-4"
        )}
      >
        {renderCardContent()}
      </div>
      {errorMsg && <ErrorCaption text={errorMsg} />}
      {open && (
        <ModalGoogleMap
          open={open}
          id={id}
          classNames={classNames}
          submitBtnClassName="px-6"
          addressSectionClassName="px-4"
          onClose={toggleModal}
          center={
            address.latLng
              ? {
                  lat: address?.latLng?.lat || 0,
                  lng: address?.latLng?.lng || 0,
                }
              : undefined
          }
          isShowGoogleMapV2={isShowGoogleMapV2}
          {...props}
        />
      )}
    </>
  )
}

export default PinpointCard
