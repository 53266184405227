const MarkerIcon = () => (
  <svg
    width="29"
    height="35"
    viewBox="0 0 29 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="pinPoint"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9052 34.2517L14.0219 34.3183L14.0685 34.345C14.2536 34.4451 14.4607 34.4975 14.671 34.4975C14.8814 34.4975 15.0885 34.4451 15.2735 34.345L15.3202 34.32L15.4385 34.2517C16.0904 33.8652 16.7265 33.4526 17.3452 33.015C18.947 31.8841 20.4436 30.6112 21.8169 29.2117C25.0569 25.895 28.4219 20.9117 28.4219 14.5C28.4219 10.8533 26.9732 7.35591 24.3946 4.77728C21.816 2.19866 18.3186 0.75 14.6719 0.75C11.0251 0.75 7.52778 2.19866 4.94916 4.77728C2.37053 7.35591 0.921875 10.8533 0.921875 14.5C0.921875 20.91 4.28854 25.895 7.52687 29.2117C8.89961 30.6111 10.3957 31.8841 11.9969 33.015C12.6162 33.4526 13.2528 33.8652 13.9052 34.2517ZM14.6719 19.5C15.998 19.5 17.2697 18.9732 18.2074 18.0355C19.1451 17.0979 19.6719 15.8261 19.6719 14.5C19.6719 13.1739 19.1451 11.9021 18.2074 10.9645C17.2697 10.0268 15.998 9.5 14.6719 9.5C13.3458 9.5 12.074 10.0268 11.1363 10.9645C10.1987 11.9021 9.67188 13.1739 9.67188 14.5C9.67188 15.8261 10.1987 17.0979 11.1363 18.0355C12.074 18.9732 13.3458 19.5 14.6719 19.5Z"
      fill="#D22B50"
    />
  </svg>
)

export default MarkerIcon
