import { cx } from "class-variance-authority"
import { ArrowLeft } from "react-feather"

import useFormLevelAddAddressStore from "../../hooks/useFormLevelAddAddressStore"

type THeaderFormProps = {
  onClickBack?: () => void
  className?: string
  isEdit?: boolean
  id?: string
}

const HeaderForm = ({
  onClickBack,
  className: classNameProps,
  isEdit = false,
  id = "add-address",
}: THeaderFormProps) => {
  const [formLevel] = useFormLevelAddAddressStore((state) => [state.level])

  return (
    <div className={cx("mt-3 flex items-center", classNameProps ?? "")}>
      {(!isEdit || (isEdit && formLevel !== "detailAddress")) &&
        onClickBack && (
          <ArrowLeft
            id={`${id}-btn-back`}
            size={24}
            className="mr-3 cursor-pointer"
            onClick={onClickBack}
          />
        )}
      <h2 className="text-tertiary500 text-lg font-bold">
        {isEdit ? "Ubah" : "Tambah"} Alamat
      </h2>
    </div>
  )
}

export default HeaderForm
