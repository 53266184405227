import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  type GetSearchRegionQueryVariables,
  type GetSearchRegionQuery,
} from "../../federatedGql/graphql"
import { federatedGqlClient } from "../../utils/graphqlClient"
import { GET_SEARCH_REGION_V2 } from "../modal-add-address/federated/queries"

export type TGetRegionSearchResponse = GenericExtractor<
  GetSearchRegionQuery["getSearchRegionV2"]
>

const useGetRegionSearch = createQuery<
  TGetRegionSearchResponse,
  GetSearchRegionQueryVariables
>({
  primaryKey: "GET_SEARCH_REGION",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_SEARCH_REGION_V2,
      variables
    )
    if (res?.getSearchRegionV2?.__typename === "GenericError") {
      throw new Error()
    }
    return res.getSearchRegionV2
  },
  refetchOnWindowFocus: false,
})

export default useGetRegionSearch
