import { type TFormModalAdd } from "./type"

export const emptyRegionDefault = {
  areaCode: "",
  id: "",
  name: "",
}

export const initForm: TFormModalAdd = {
  id: "",
  detailAddress: {
    label: "",
    name: "",
    phone: "",
    province: emptyRegionDefault,
    city: emptyRegionDefault,
    district: emptyRegionDefault,
    village: emptyRegionDefault,
    postalCode: emptyRegionDefault,
    googleMaps: {
      addressName: "",
      addressSecondary: "",
      placeId: "",
      districtAreaCode: "",
    },
    address: "",
    notes: "",
    isPrimaryAddress: false,
  },
}
